.shareContainer {
  a {
    div {
      border-radius: 0;
    }
  }
  .copy-button {
    border-radius: 0 !important;
  }
}

@media only screen and (max-width: 500px) {
  .page.finish {
    // margin-top: 50vh !important;
    // padding-top: 250% !important;
  }
}
