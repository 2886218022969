:root {
  // --background: rgb(0, 0, 0);
  // --background: rgb(244, 101, 79);
  --background: #ec7154;
  --fontCustom: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  --fontDefault: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
  background: var(--background);
  background-color: var(--background) !important;
  text-align: center;
}

.index {
  background: var(--background);
  background-color: var(--background);
}

.page {
  width: 100%;
  display: flex;
  // max-width: 400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.af-header {
  &.h1 {
    // color: #060606;
    color: #fff;
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
    font-family: var(--fontCustom);
  }
  &.h2 {
    font-size: 2rem;
  }
  &.img {
    width: 40%;
  }
  &:hover {
    cursor: pointer;
  }
}

.af-input {
  height: 36px;
  width: 280px;
  outline: none;
  font-size: 1rem;
  text-align: center;
  background: #fff;
  font-family: var(--fontCustom);
  border: 1px solid black;
  transition: all 0.3s ease;
  &:focus {
    background: #ffffff;
  }
}

.af-box-input {
  div {
    width: auto;
    margin-top: initial;
    div {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      flex-direction: row;
      input {
        border: none;
        color: #222;
        font-size: 2em;
        font-weight: 600;
        border-radius: 0;
        background: #fff;
        font-family: var(--fontCustom);
        text-transform: uppercase;
      }
    }
  }
}

.af-button {
  // padding: 0.8rem 4rem;
  width: 229px;
  height: 45px;
  outline: none;
  font-size: 18px;
  line-height: 23px;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  background: #ffd900;
  box-sizing: border-box;
  transition: all 0.3s ease;
  border: 1px solid #000000;
  font-family: var(--fontCustom);
  box-shadow: 8px 8px 0px #000000;
  &:hover {
    cursor: pointer;
    color: #fff;
    background: #000000;
    box-shadow: 9px 9px 0px #ffd900;
  }
  &:disabled {
    color: #f5f5f1;
    background: #060606;
    box-shadow: 9px 9px 0px #f2c94c;
  }
}

.af-para {
  color: #fff;
  line-height: 20;
  font-size: 16px;
  font-weight: 500;
  max-width: 400px;
  font-weight: 600;
  line-height: 20px;
  // color: #363636;
  font-family: var(--fontCustom);
  &.sgwf {
    font-weight: 500;
  }
  &.q1 {
    white-space: pre-line;
  }
}

.cardParent {
  display: flex;
  min-width: 40vw;
  align-items: center;
  justify-content: center;
  .cardLink {
    width: 100%;
    .card {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      min-width: 30vw;
      min-height: 200px;
      border-radius: 4pt;
      background: #ffd900;
      transition: all 0.3s ease-in-out;
      &:hover,
      &:focus {
        box-shadow: 8px 8px 0px #000000;
      }
      * {
        color: black;
      }
    }
  }
}

.spinner {
  .container {
    span {
      background-color: white !important;
    }
  }
}

.tryAgain {
  color: rgb(102, 75, 255);
  font-weight: 500;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: blue;
    cursor: pointer;
  }
}

// React router paste

.route-wrapper > div {
  top: 50%;
  left: 50%;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
}

// Media Queries
@media only screen and (max-width: 500px) {
  .cardParent {
    width: 96vw;
  }
}

@media only screen and (max-width: 660px) {
  .af-header {
    &.img {
      width: 100%;
    }
  }
  .af-box-input {
    div {
      div {
        input {
          padding: 0;
          margin: 2px;
          width: 100%;
          height: 32px;
          overflow: visible;
          font-size: 1.2rem;
        }
      }
    }
  }
}
@media only screen and (min-width: 1300px) {
  .af-header {
    &.img {
      width: 20%;
    }
  }
}
